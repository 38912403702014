
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Button } from '@/components/atom/Button';
import { ArrowLeft } from 'iconoir-react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useTranslation } from '../hooks/support/translate';

const Custom404: NextPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <Head>
                <title>{t('404.head')}</title>
            </Head>
            <div className="container mx-auto px-4 flex flex-col justify-center h-[75vh]">
                <div className="mb-4 t__heading--h5">{t('404.title')}</div>
                <div className="t__heading--h3 mb-9">{t('404.caption')}</div>
                <div className="flex">
                    <Link href="/" passHref>
                        <Button size="large" name="Back">
                            <div className="flex items-center space-x-2">
                                <ArrowLeft fr="true" />
                                <span className="t__button--u1">{t('404.back')}</span>
                            </div>
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Custom404;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  